import * as React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import samsumgJson from './samsung.json'
import Content from './content'
import Banner from '../../../components/molecules/Banner'

const SamsungShowroom = () => {
  const { relational } = samsumgJson

  return (
    <Layout page="Samsung Showroom">
      <Banner image="banner-samsung.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Samsung Showroom" />

export default SamsungShowroom
